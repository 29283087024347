import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom/client";
import {Map, GridLayer, TileLayer} from "leaflet";
import {Popup} from "tdesign-mobile-react";
import axios from "axios";

import "leaflet/dist/leaflet.css";
import "./index.css";

const App = ({...props}) => {
    const controller = new AbortController();
    const [visible, setVisible] = useState(false);
    const [load, setLoad] = useState(false);
    const [block, setBlock] = useState({});
    const [cubicX, setCubicX] = useState(-1);
    const [cubicY, setCubicY] = useState(-1);

    useEffect(() => {
        const map = new Map("map", {
            crs: L.CRS.Simple,
            center: [0, 0],
            minZoom: 0,
            maxZoom: 0,
            zoomControl: false,
            attributionControl: false,
        });

        const bounds = [
            [0, 0],
            // [3584, 3584],
            [3584 * 50, 3584 * 50],
        ];

        map.fitBounds(bounds);

        const gaodeLayer = new TileLayer(process.env.API_URL + "/block/image/{x}/{y}/{z}", {
            subdomains: "",
            tileSize: 64,
            noWrap: true,
            bounds: bounds,
        });
        gaodeLayer.addTo(map);

        map.on("click", (event) => {
            let x = Math.floor(parseInt(event.latlng.lng) / 64);
            let y = Math.ceil(parseInt(event.latlng.lat) / 64);

            setVisible(true);
            setCubicX(x);
            setCubicY(y);

            axios({
                method: "post",
                url: process.env.API_URL + "/block/frontend/mobile/v1/item",
                signal: controller.signal,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "",
                    Locale: "",
                },
                data: {
                    blockType: 0,
                    blockX: x,
                    blockY: y,
                },
            })
                .then((response) => {
                    if (response.data.code === 0) {
                        setLoad(true);
                        setBlock(response.data.data);
                    }
                })
                .catch(function (error) {});
        });

        console.log(window.location.search);

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        if (visible == false) {
            setLoad(false);
            setBlock({});
        }
    }, [visible]);

    return (
        <React.Fragment>
            <div id="map"></div>
            <Popup
                visible={visible}
                placement="bottom"
                onVisibleChange={() => {
                    setVisible(false);
                }}
            >
                <div>
                    <div
                        style={{
                            padding: "20px 20px 100px 20px",
                            height: "500px",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {load ? (
                            <div
                                style={{
                                    flex: 1,
                                    maxWidth: "750px",
                                }}
                            >
                                <img
                                    style={{
                                        width: "100%",
                                    }}
                                    src={process.env.FILE_URL + block.advertisementImage}
                                    alt=""
                                />
                                <div
                                    style={{
                                        marginTop: "10px",
                                        fontSize: "16px",
                                        whiteSpace: "pre-line",
                                    }}
                                >
                                    {block.advertisementContent}
                                </div>
                            </div>
                        ) : null}
                        {load && typeof block.blockId == "undefined" ? (
                            <div
                                style={{
                                    flexShrink: 0,
                                    width: "100%",
                                    height: "50px",
                                    lineHeight: "50px",
                                    textAlign: "center",
                                    borderRadius: "25px",
                                    backgroundColor: "#1fab89",
                                    marginTop: "20px",
                                    marginBottom: "100px",
                                    fontSize: "18px",
                                    color: "#ffffff",
                                }}
                                onClick={() => {
                                    wx.miniProgram.navigateTo({
                                        url: "/view/cubic/exchange?cubicX=" + cubicX + "&cubicY=" + cubicY,
                                    });

                                    setVisible(false);
                                }}
                            >
                                我要兑换
                            </div>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                        {load && block.blockId && !block.advertisementImage ? (
                            <div
                                style={{
                                    flexShrink: 0,
                                    width: "100%",
                                    height: "50px",
                                    lineHeight: "50px",
                                    textAlign: "center",
                                    borderRadius: "25px",
                                    backgroundColor: "#1fab89",
                                    marginTop: "20px",
                                    marginBottom: "100px",
                                    fontSize: "18px",
                                    color: "#ffffff",
                                }}
                            >
                                已售出
                            </div>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </div>
                </div>
            </Popup>
        </React.Fragment>
    );
};

let root;
function render(props) {
    const {container} = props;
    root = ReactDOM.createRoot(container ? container.querySelector("#root") : document.querySelector("#root"));
    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
}

render({});
